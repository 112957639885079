import Toast from 'react-bootstrap/Toast';
import { useSelector, useDispatch } from 'react-redux'
import { removeToast, selectToast } from '../../slices/toastMessageSlice';
import ToastContainer from 'react-bootstrap/ToastContainer';

const ToastComponent = () => {
    const toastMessage = useSelector(selectToast)
    const dispatch = useDispatch()
    const hideToast = () => {
        dispatch(removeToast())
    }
    return <>
        {toastMessage ? <>
            <ToastContainer
                className="p-3"
                position='middle-center'
                style={{ zIndex: 100 }}
             >
            <Toast onClose={hideToast} show={true} delay={3000} autohide position='middle-center' bg={toastMessage.type}>
                <Toast.Header>
                    <strong className="me-auto" bg={toastMessage.type}>{toastMessage.title}</strong>
                </Toast.Header>
                <Toast.Body className={'Dark'}>
                    {toastMessage.message}
                </Toast.Body>

            </Toast>
            </ToastContainer>
        </> : null}
    </>
}

export default ToastComponent;