import { useFormInputValidation } from "react-form-input-validation";
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import helper from "../../utils/helper";
import { useState } from "react";

const ValidateOtpComponent = ({ setFormState, showSuccessMessage, email, setOtp}) => {
    const [serverErrors, setServerErrors] = useState({})
    const [fields, errors, form] = useFormInputValidation({
        otp: '',
    }, {
        otp: "required|integer",
    });

    const onSubmit = async (event) => {
        const isValid = await form.validate(event);
        if (isValid) {
            const formField = {
                ...fields, ...{
                    email:email
                }
            }
            const respone = await helper.sendApiRequest('POST', 'auth/verify-otp', formField)
            const result = await respone.json()
            if (result.success) {
                // navigate('/')
                setOtp(fields.otp)
                setFormState('reset');
                showSuccessMessage(result.message)
            } else {
                if (result.errors) {
                    setServerErrors(result.errors)
                } else {
                    alert('Unable to process, please try later')
                }
            }
        }
    }



    return <>
        <h4 className='midHead'>Verify Otp</h4>
        <Form noValidate onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail" >
                <Form.Label>Otp</Form.Label>
                <Form.Control
                    name="otp"
                    type="otp" placeholder="Type Your otp"
                    onChange={form.handleChangeEvent}
                    isInvalid={errors.otp || serverErrors.otp}
                />

                <Form.Control.Feedback type="invalid">
                    {errors.otp || ''}
                </Form.Control.Feedback>

                <Form.Control.Feedback type="invalid">
                    {serverErrors.otp?.msg || ''}
                </Form.Control.Feedback>

            </Form.Group>
            <div className="tablCont">
                <div className='submitBtn'>
                    <button className="btn btn-primary" >Submit</button>
                </div>
            </div>
        </Form>
    </>
}

export default ValidateOtpComponent