import React from 'react';
export default function RightPanel(props) {
  return (
    <>
      <div className="rightPanel">
        <div className="boxSpace">{props.children}</div>
      </div>
    </>
  );
}
