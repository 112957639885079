import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';

function Loader() {
  return (
    <>
    <Container className='d-flex justify-content-center'>
        <Spinner animation="border" variant="primary" />
    </Container>
    </>
  );
}

export default Loader;