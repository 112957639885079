import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import logo from './../static/images/logo.svg';

import { selectLoggedInUserToken } from '../slices/loggedInUserSlice';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const GuestLayout = () => {
  const user = useSelector(selectLoggedInUserToken);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      return navigate('/');
    }
  }, [user]);

  return (
    <>
      <div className="login">
        <ul>
          <li>
            <a href="#">
              {' '}
              <img
                src={logo}
                style={{
                  maxWidth: '300px',
                }}
                alt="logo"
              />
            </a>
          </li>
        </ul>
        <div className="loginBox">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default GuestLayout;
