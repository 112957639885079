import { useFormInputValidation } from "react-form-input-validation";
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import helper from "../../utils/helper";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ResetPasswordComponent = ({email, otp, showSuccessMessage}) => {
    const navigate = useNavigate()

    const [serverErrors, setServerErrors] = useState({})
    const [fields, errors, form] = useFormInputValidation({
        password: '',
        passwordconf: '',
    }, {
        password: "required",
        passwordconf: "required:same:password",
    });

    const onSubmit = async (event) => {
        const isValid = await form.validate(event);
        if (isValid) {
            const formField = {
                ...fields, ...{
                    email:email,
                    otp: otp
                }
            }

            const respone = await helper.sendApiRequest('POST', 'auth/reset-password', formField)
            const result = await respone.json()
            if (result.success) {
                setTimeout(() => {
                    navigate('/login')
                }, 3000)
               // showResetForm();
                showSuccessMessage(result.message)
            } else {
                if (result.errors) {
                    setServerErrors(result.errors)
                } else {
                    alert('Unable to process, please try later')
                }
            }
        }
    }

    return <>
        <h4 className='midHead'>Reset Password</h4>
        <Form noValidate onSubmit={onSubmit}>

            <Form.Group className="mb-3" controlId="email" >
                <Form.Label>New Password</Form.Label>
                <Form.Control
                    name="password"
                    type="password" placeholder="Password"
                    onChange={form.handleChangeEvent}
                    isInvalid={errors.password || serverErrors.password}
                />

                <Form.Control.Feedback type="invalid">
                    {errors.password || ''}
                </Form.Control.Feedback>

                <Form.Control.Feedback type="invalid">
                    {serverErrors.password?.msg || ''}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="passwordconf" >
                <Form.Label>Confirm new Password</Form.Label>
                <Form.Control
                    name="passwordconf"
                    type="password" placeholder="Confirm Password"
                    onChange={form.handleChangeEvent}
                    isInvalid={errors.passwordconf || serverErrors.passwordconf}
                />

                <Form.Control.Feedback type="invalid">
                    {errors.passwordconf?.replace('passwordconf', 'password confirmation') || ''}
                </Form.Control.Feedback>

                <Form.Control.Feedback type="invalid">
                    {serverErrors.passwordconf?.msg?.replace('passwordconf', 'password confirmation') || ''}
                </Form.Control.Feedback>
            </Form.Group>

            <div className="tablCont">
                <div className='submitBtn'>
                    <button className="btn btn-primary" >Submit</button>
                </div>
            </div>
        </Form>
    </>
}

export default ResetPasswordComponent;