import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import ForgotPasswordComponent from '../../components/Guest/ForgotPasswordComponent';
import ResetPasswordComponent from '../../components/Guest/ResetPasswordComponent';
import Alert from 'react-bootstrap/Alert';
import ValidateOtpComponent from '../../components/Guest/ValidateOtpComponent';
export default function ForgotPassword() {
    const [formState, setFormState] = useState('forgot')
    const [email, setEmail] = useState(null)
    const [successMessage, setSuccessMessage] = useState(null)
    const [otp, setOtp] = useState(null)



    const showSuccessMessage  = (message) => {
        setSuccessMessage(message)
    }

    return (
        <>
            {
                successMessage ? <Alert variant='primary'>{successMessage}</Alert> : null
            }
            {
                formState === 'forgot' ?
                    <ForgotPasswordComponent
                        setFormState={setFormState}
                        showSuccessMessage={showSuccessMessage}
                        setEmail={setEmail}
                    /> : formState === 'validateOtp' ?
                   <ValidateOtpComponent
                        email={email}
                        setFormState={setFormState}
                        showSuccessMessage={showSuccessMessage}
                        setOtp={setOtp}
                    /> :
                    <ResetPasswordComponent
                        showSuccessMessage={showSuccessMessage}
                        email={email}
                        otp={otp}
                    />
            }
            <div className='subBtn secSpcTop'>
                <div className="subText"><Link to="/login" >Login</Link></div>
            </div>
        </>
    )

}