import { useState, useEffect } from 'react';
import Loader from '../../components/commons/Loader';
import Table from 'react-bootstrap/Table';
import helper from '../../utils/helper';
// import Form from 'react-bootstrap/Form';
import Paginate from './../../components/commons/Paginate';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Button } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
// const formatter = new Intl.DateTimeFormat('en-GB');

const WebsiteListPage = () => {
  const [searchParams] = useSearchParams();
  const showmalicious = searchParams.get('malicious');
  
  const [masterData, setMastarData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoaded, setLoaded] = useState(false);
  const [filterData, setFilterData] = useState({
    domain: '',
    showSites: showmalicious == '1' ? 'malicious' : '',
  });

  const [formFilers, setFormFilters] = useState(filterData);

  useEffect(() => {
    try {
      fetchData();
    } catch (error) {}
  }, [page, formFilers]);

  const fetchData = async () => {
    const response = await helper.sendApiRequest('GET', 'websites', {
      ...formFilers,
      ...{ page: page },
    });
    const data = await response.json();

    if ('success' in data && data.success) {
      setMastarData(data.websiteData);
      setLoaded(true);
      setTotalPage(data.totalPages);
    } else {
    }
  };

  const formHandler = (e) => {
    setFilterData({
      ...filterData,
      ...{
        [e.target.name]: e.target.value,
      },
    });
  };

  const searchSites = () => {
    setFormFilters(filterData);
    setPage(1);
    // if(page == 1){
    //     fetchData()
    // }
  };

  return (
    <>
      <div className="topHead">
        <h3>Urls</h3>
      </div>

      <Form>
        <Row>
          <Col>
            <Form.Control placeholder="Domain" name="domain" onInput={formHandler} />
          </Col>
          <Col>
            <Form.Select
              aria-label="Default select example"
              onChange={formHandler}
              name="showSites"
              defaultValue={filterData.showSites}
            >
              <option value="">Show All</option>
              <option value="malicious">Malicious</option>
              <option value="nonmalicious">Non malicious</option>
            </Form.Select>
          </Col>

          <Col>
            <Button variant="primary" type="button" onClick={searchSites}>
              Search
            </Button>
          </Col>
        </Row>
      </Form>

      {!isLoaded ? (
        <Loader />
      ) : (
        <>
          <Table striped bordered hover size="sm" responsive>
            <thead>
              <tr>
                <th>Domain </th>
                <th>Malicious</th>
                <th>Safe</th>
                <th>First Checked</th>
                <th>Last Checked</th>
              </tr>
            </thead>
            <tbody>
              {masterData.length > 0 ? (
                masterData.map((item, index) => {
                  return (
                    <tr key={item._id}>
                      <td>{item.domain}</td>
                      <td>{item.isMalicious ? 'Yes' : 'No'}</td>
                      <td>{item.isSafe ? 'Yes' : 'No'}</td>
                      <td>{new Date(item.createdDateTime).toDateString()}</td>
                      <td>{new Date(item.lastUpdateDate).toDateString()}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={5} className="text-center">
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <Paginate totalPage={totalPage} pageChangeHandler={setPage} currentPage={page} />
        </>
      )}
    </>
  );
};

export default WebsiteListPage;
