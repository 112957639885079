import Pagination from 'react-bootstrap/Pagination';

function Paginate({totalPage, pageChangeHandler, currentPage}) {
    if(totalPage <= 1){
        return null
    }

    const handlePageChange = (page) => {
        pageChangeHandler(page)
    }

    return (
        <Pagination>
            <Pagination.Prev disabled={currentPage === 1} onClick={() => {handlePageChange(currentPage - 1)}}/>
            <Pagination.Item>{currentPage}</Pagination.Item>
            <Pagination.Next disabled={currentPage === totalPage} onClick={() => {handlePageChange(currentPage + 1)}}/>

        </Pagination>
    );
}

export default Paginate;
