
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { useFormInputValidation } from "react-form-input-validation";
import Button from 'react-bootstrap/Button';
import { Link, useNavigate } from 'react-router-dom';
import helper from '../../utils/helper';
import { useDispatch } from 'react-redux';
import { setToast } from '../../slices/toastMessageSlice';
const CreateCampaign = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [serverErrors, setServerErrors] = useState({})

    const [fields, errors, form] = useFormInputValidation({
        sourceDomain: '',
        destinationDomain: '',
        notificationTitle: '',
        notificationBody: '',
        iconImage: '',
    }, {
        sourceDomain: "required|url",
        destinationDomain: 'required|url',
        notificationTitle: 'required',
        notificationBody: 'required',
        iconImage: 'required|file',
    });

    const onSubmit = async (event) => {
        const isValid = await form.validate(event);
        if (isValid) {
            const formData = new FormData(document.getElementById("createCampaignForm"))
            const respone = await helper.sendApiRequest('POST', 'campaign', formData)
            const result = await respone.json()
            if (result.success) {
                dispatch(setToast({
                    type: 'success',
                    message: result.message ? result.message : 'Campaign created',
                    title: 'Success'
                }))
                navigate('/campaign')
            } else {
                if (result.errors) {
                    setServerErrors(result.errors)
                } else {
                    alert(result.errors)
                }
            }

        }
    }


    return <>
        <Form noValidate onSubmit={onSubmit} id="createCampaignForm" encType='multipart/form-data'>
            <Form.Group className="mb-3" controlId="exampleForm.sourceDomain">
                <Form.Label>Source</Form.Label>
                <Form.Control type="text" name="sourceDomain" onInput={form.handleChangeEvent} value={fields.sourceDomain} isInvalid={errors.sourceDomain || serverErrors.sourceDomain} />
                <Form.Text className="text-muted">
                    Fully qualified domain e.g. https://www.google.com
                </Form.Text>

                <Form.Control.Feedback type="invalid">
                    {errors.sourceDomain?.replace('sourceDomain', 'source') || ''}
                </Form.Control.Feedback>

                <Form.Control.Feedback type="invalid">
                    {serverErrors.sourceDomain?.msg || ''}
                </Form.Control.Feedback>
            </Form.Group>


            <Form.Group className="mb-3" controlId="exampleForm.destinationDomain">
                <Form.Label>Destination</Form.Label>
                <Form.Control type="text" name="destinationDomain" onInput={form.handleChangeEvent} isInvalid={errors.destinationDomain || serverErrors.destinationDomain} />
                <Form.Text className="text-muted">
                    Fully qualified domain e.g. https://www.google.com
                </Form.Text>

                <Form.Control.Feedback type="invalid">
                    {errors.destinationDomain?.replace('destinationDomain', 'destination') || ''}
                </Form.Control.Feedback>

                <Form.Control.Feedback type="invalid">
                    {serverErrors.destinationDomain?.msg || ''}
                </Form.Control.Feedback>

            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.notificationTitle">
                <Form.Label>Notification title</Form.Label>
                <Form.Control type="text" name="notificationTitle" onInput={form.handleChangeEvent} isInvalid={errors.notificationTitle || serverErrors.notificationTitle} />

                <Form.Text className="text-muted">
                    e.g. Title
                </Form.Text>

                <Form.Control.Feedback type="invalid">
                    {errors.notificationTitle?.replace('notificationTitle', 'title') || ''}
                </Form.Control.Feedback>

                <Form.Control.Feedback type="invalid">
                    {serverErrors.notificationTitle?.msg || ''}
                </Form.Control.Feedback>

            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.notificationBody">
                <Form.Label>Notification body</Form.Label>
                <Form.Control type="text" name="notificationBody" onInput={form.handleChangeEvent} isInvalid={errors.notificationBody || serverErrors.notificationBody} />

                <Form.Text className="text-muted">
                    e.g. Body
                </Form.Text>

                <Form.Control.Feedback type="invalid">
                    {errors.notificationBody?.replace('notificationBody', 'body') || ''}
                </Form.Control.Feedback>

                <Form.Control.Feedback type="invalid">
                    {serverErrors.notificationBody?.msg || ''}
                </Form.Control.Feedback>

            </Form.Group>


            <Form.Group controlId="iconImage" className="mb-3"  >
                <Form.Label>Icon Image</Form.Label>
                <Form.Control type="file" accept="image/png, image/jpeg" onInput={form.handleChangeEvent} isInvalid={errors.iconImage || serverErrors.iconImage} value={fields.iconImage} required={true} name="iconImage" />
                <Form.Text className="text-muted">
                    PNG, JPEG images upto 1 MB
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                    {errors.iconImage?.replace('iconImage', 'icon') || ''}
                </Form.Control.Feedback>

                <Form.Control.Feedback type="invalid">
                    {serverErrors.iconImage?.msg || ''}
                </Form.Control.Feedback>

            </Form.Group>

            <Button type="submit" >Submit</Button>

            <Link to="/campaign">
                <Button type="button" variant='secondary' className='m-2'>Cancel</Button>
            </Link>
        </Form>
    </>
}

export default CreateCampaign;