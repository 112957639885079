import { createSlice } from '@reduxjs/toolkit';

var defautlUser = localStorage.getItem('user');
if (defautlUser) {
  defautlUser = JSON.parse(defautlUser);
}
const initialState = {
  token: localStorage.getItem('token'),
  user: defautlUser, // localStorage.getItem('user')
};

export const loggedInUserSlice = createSlice({
  name: 'loggedInUser',
  initialState,
  reducers: {
    setUser: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('user', JSON.stringify(action.payload.user));
      state.token = action.payload.token;
      state.user = action.payload.user;
    },

    removeUser: (state) => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      state.token = null;
      state.user = null;
    },
  },
});

export const selectLoggedInUserToken = (state) => state.loggedInUser.token;
export const selectLoggedInUser = (state) => state.loggedInUser.user;

// Action creators are generated for each case reducer function
export const { setUser, removeUser } = loggedInUserSlice.actions;

export default loggedInUserSlice.reducer;
