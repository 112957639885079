import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  toast: null,
};

export const toastMessageSlice = createSlice({
  name: 'toastMessage',
  initialState,
  reducers: {
    setToast: (state, action) => {
      state.toast = action.payload;
    },

    removeToast: (state) => {
      state.toast = null;
    },
  },
});

export const selectToast = (state) => state.toastMessage.toast;

// Action creators are generated for each case reducer function
export const { setToast, removeToast } = toastMessageSlice.actions;

export default toastMessageSlice.reducer;
