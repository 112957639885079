import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { FormCheck } from 'react-bootstrap';
import Feedback from 'react-bootstrap/esm/Feedback';
import helper from '../../utils/helper';
import { setToast } from '../../slices/toastMessageSlice';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

export default function ApiIntegrationForm({ defaultValues }) {
  const [serverErrors, setServerErrors] = useState({});
  const dispatch = useDispatch();

  const ActiveApiSchema = yup.object().shape({
    activeUrlCheckingApis: yup.array().min(1, 'Must be one api will be active.').required(),
    ipQualityScoreApiRotationPoint: yup.string().when('activeUrlCheckingApis', {
      is: (value) => value.includes('ipQualityScore'),
      then: () => yup.string().required('This field is required'),
    }),
    googleWebRiskApiRotationPoint: yup.string().when('activeUrlCheckingApis', {
      is: (value) => value.includes('googleWebRisk'),
      then: () => yup.string().required('This field is required'),
    }),
    virusTotalApiRotationPoint: yup.string().when('activeUrlCheckingApis', {
      is: (value) => value.includes('virusTotal'),
      then: () => yup.string().required('This field is required'),
    }),
  });

  const submitValidForm = async (values) => {
    const respone = await helper.sendApiRequest('POST', 'setting/api', values);
    const result = await respone.json();
    if (result.success) {
      dispatch(
        setToast({
          type: 'primary',
          message: result.message,
          title: 'Success',
        }),
      );
    } else {
      if (result.errors) {
        setServerErrors(result.errors);
      } else {
        dispatch(
          setToast({
            type: 'danger',
            message: result.message,
            title: 'Error',
          }),
        );
      }
    }
  };

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: {
      activeUrlCheckingApis: defaultValues?.activeUrlCheckingApis?.split(',') || [],
      ipQualityScoreApiRotationPoint: defaultValues?.ipQualityScoreApiRotationPoint || '',
      googleWebRiskApiRotationPoint: defaultValues?.googleWebRiskApiRotationPoint || '',
      virusTotalApiRotationPoint: defaultValues?.virusTotalApiRotationPoint || '',
    },
    validationSchema: ActiveApiSchema,
    onSubmit: submitValidForm,
  });

  return (
    <>
      <Form noValidate onSubmit={handleSubmit} id="createCampaignForm">
        <Form.Group className="mb-3" controlId="exampleForm.activeUrlCheckingApi">
          <Form.Label>Active Api</Form.Label>

          <FormCheck>
            <FormCheck.Input
              isInvalid={errors.ipQualityScoreApiRotationPoint}
              type="checkbox"
              name="activeUrlCheckingApis"
              id="activeUrlCheckingApi-ipQualityScore"
              value="ipQualityScore"
              defaultChecked={values.activeUrlCheckingApis.includes('ipQualityScore')}
              onChange={handleChange}
            />
            <FormCheck.Label htmlFor="activeUrlCheckingApi-ipQualityScore">
              IP Quality Score
            </FormCheck.Label>
            <Form.Control
              type="text"
              placeholder="Number of hit within a rotation cycle."
              name="ipQualityScoreApiRotationPoint"
              value={values.ipQualityScoreApiRotationPoint}
              onChange={handleChange}
              isInvalid={errors.ipQualityScoreApiRotationPoint}
              style={{
                width: '300px',
              }}
            />

            <Feedback type="invalid">{errors.ipQualityScoreApiRotationPoint}</Feedback>
          </FormCheck>

          <FormCheck>
            <FormCheck.Input
              isInvalid={errors.googleWebRiskApiRotationPoint}
              type="checkbox"
              name="activeUrlCheckingApis"
              id="activeUrlCheckingApi-googleWebRisk"
              value="googleWebRisk"
              defaultChecked={values.activeUrlCheckingApis.includes('googleWebRisk')}
              onChange={handleChange}
            />
            <FormCheck.Label htmlFor="activeUrlCheckingApi-googleWebRisk">
              Google Web Risk
            </FormCheck.Label>
            <Form.Control
              type="number"
              placeholder="Number of hit within a rotation cycle."
              name="googleWebRiskApiRotationPoint"
              value={values.googleWebRiskApiRotationPoint}
              onChange={handleChange}
              isInvalid={errors.googleWebRiskApiRotationPoint}
              style={{
                width: '300px',
              }}
            />

            <Feedback type="invalid">{errors.googleWebRiskApiRotationPoint}</Feedback>
          </FormCheck>

          <FormCheck>
            <FormCheck.Input
              isInvalid={errors.virusTotalApiRotationPoint}
              type="checkbox"
              name="activeUrlCheckingApis"
              id="activeUrlCheckingApi-virusTotal"
              value="virusTotal"
              defaultChecked={values.activeUrlCheckingApis.includes('virusTotal')}
              onChange={handleChange}
            />
            <FormCheck.Label htmlFor="activeUrlCheckingApi-virusTotal">Virus Total</FormCheck.Label>
            <Form.Control
              type="number"
              placeholder="Number of hit within a rotation cycle."
              name="virusTotalApiRotationPoint"
              value={values.virusTotalApiRotationPoint}
              onChange={handleChange}
              isInvalid={errors.virusTotalApiRotationPoint}
              style={{
                width: '300px',
              }}
            />

            <Feedback type="invalid">{errors.virusTotalApiRotationPoint}</Feedback>
          </FormCheck>

          <Form.Text className="text-danger">{errors.activeUrlCheckingApis}</Form.Text>

          <Form.Text className="text-muted d-block">
            Active API enabled for rotation cycle with number of request each cycle.
          </Form.Text>
        </Form.Group>

        <Button type="submit">Submit</Button>
      </Form>
    </>
  );
}
