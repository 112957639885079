import React from 'react';
import logo from './../../static/images/logo.svg'; // Tell Webpack this JS file uses this image
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { removeUser } from '../../slices/loggedInUserSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectLoggedInUser } from '../../slices/loggedInUserSlice';
import { setToast } from '../../slices/toastMessageSlice';

export default function Header() {
    const dispatch = useDispatch()
    const logginInUser = useSelector(selectLoggedInUser)
    const logoutUser = () => {
        dispatch(removeUser())
        dispatch(setToast({
            type: 'primary',
            message: 'Logged out successully',
            title: 'Success'
        }))
    }
    return (
        <>
            <div className='hedaer'>
                <Navbar expand="lg" className="">
                    <Container fluid>
                        <Navbar.Brand to="/" as={Link}><img src={logo} style={{
                            maxWidth: "200px"
                        }} alt='logo' /></Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll" className='navScroll'>
                            <div className='userInfo'>
                            {/* <img src={userImg} alt='user' /> */}
                            <div className='userDetl'>
                                <h4>{logginInUser?.name}</h4>
                                <p>{logginInUser?.email}</p>
                            </div>
                            </div>
                            <Nav
                                className="usrImg"
                                navbarScroll
                            >
                                <NavDropdown title="" className='user' id="navbarScrollingDropdown">
                                    <NavDropdown.Item as={Link} to="/profile">Profile</NavDropdown.Item>
                                    <NavDropdown.Item as="a" onClick={logoutUser}>Logout</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        </>
    )

}