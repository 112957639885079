import React from 'react';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { setToast } from '../../slices/toastMessageSlice';
import { useDispatch } from 'react-redux';
import { useFormInputValidation } from "react-form-input-validation";
import { Button } from 'react-bootstrap';
import helper from '../../utils/helper';

export default function UpdatePasswordForm() {
    const [serverErrors, setServerErrors] = useState({})
    const dispatch = useDispatch()
    const [fields, errors, form] = useFormInputValidation({
        currentPassword: '',
        password: '',
        passwordconf: '',
    }, {
        currentPassword: "required",
        password: "required",
        passwordconf: "required",
    });

    const onSubmit = async (event) => {

        const isValid = await form.validate(event);
        if (isValid) {
            const respone = await helper.sendApiRequest('POST', 'profile/update-password', fields)
            const result = await respone.json()
            if (result.success) {

                dispatch(setToast({
                    type: 'primary',
                    message: result.message || 'Profile Updated',
                    title: 'Success'
                }))

            } else {
                if (result.errors) {
                    setServerErrors(result.errors)
                } else {
                    dispatch(setToast({
                        type: 'danger',
                        message: result.message,
                        title: 'Error'
                    }))
                }
            }
        }
    }

    return (
        <>
            <Form noValidate onSubmit={onSubmit} id="updatePasswordForm">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Current Password</Form.Label>
                    <Form.Control type="password"
                        placeholder="Type your current password"
                        name="currentPassword"
                        onInput={form.handleChangeEvent}
                        defaultValue={fields.currentPassword}
                        isInvalid={errors.currentPassword || serverErrors.currentPassword}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors.currentPassword?.replace('currentPassword', 'Current password') || ''}
                    </Form.Control.Feedback>

                    <Form.Control.Feedback type="invalid">
                        {serverErrors.currentPassword?.msg || ''}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control  placeholder="Type your new password"
                        type="password"
                        name="password"
                        onInput={form.handleChangeEvent}
                        defaultValue={fields.password}
                        isInvalid={errors.password || serverErrors.password}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors.password?.replace('password', 'New password') || ''}
                    </Form.Control.Feedback>

                    <Form.Control.Feedback type="invalid">
                        {serverErrors.password?.msg || ''}
                    </Form.Control.Feedback>

                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                        placeholder="Confirm your new password"
                        name="passwordconf"
                        onInput={form.handleChangeEvent}
                        defaultValue={fields.passwordconf}
                        isInvalid={errors.passwordconf || serverErrors.passwordconf}
                        type="password"
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors.passwordconf?.replace('passwordconf', 'New password confirmation') || ''}
                    </Form.Control.Feedback>

                    <Form.Control.Feedback type="invalid">
                        {serverErrors.passwordconf?.msg || ''}
                    </Form.Control.Feedback>

                </Form.Group>
                <Button type="submit" >Submit</Button>
            </Form>
        </>
    )

}