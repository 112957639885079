import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { setToast } from '../../slices/toastMessageSlice';
import { useDispatch } from 'react-redux';
import { useFormInputValidation } from 'react-form-input-validation';
import {
  selectLoggedInUser,
  selectLoggedInUserToken,
  setUser,
} from '../../slices/loggedInUserSlice';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { Button } from 'react-bootstrap';
import helper from '../../utils/helper';
import { useNavigate } from 'react-router-dom';
export default function ProfileForm() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const token = useSelector(selectLoggedInUserToken);
  const [serverErrors, setServerErrors] = useState({});
  const loggedInUser = useSelector(selectLoggedInUser);
  const [fields, errors, form] = useFormInputValidation(
    {
      name: loggedInUser?.name,
      email: loggedInUser?.email,
    },
    {
      email: 'required|email',
      name: 'required',
    },
  );

  useEffect(() => {});

  const onSubmit = async (event) => {
    const isValid = await form.validate(event);
    if (isValid) {
      const respone = await helper.sendApiRequest('POST', 'profile', fields);
      const result = await respone.json();
      if (result.success) {
        // alert('Settings saved successfully')
        dispatch(
          setToast({
            type: 'primary',
            message: result.message || 'Profile Updated',
            title: 'Success',
          }),
        );

        dispatch(
          setUser({
            token: token,
            user: fields,
          }),
        );

        navigate(0);
      } else {
        if (result.errors) {
          setServerErrors(result.errors);
        } else {
          dispatch(
            setToast({
              type: 'danger',
              message: result.message,
              title: 'Error',
            }),
          );
        }
      }
    }
  };

  return (
    <>
      <Form noValidate onSubmit={onSubmit} id="createProfileForm">
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Your name"
            name="name"
            onInput={form.handleChangeEvent}
            defaultValue={fields.name}
            isInvalid={errors.name || serverErrors.name}
          />

          <Form.Control.Feedback type="invalid">
            {errors.name?.replace('name', 'source') || ''}
          </Form.Control.Feedback>

          <Form.Control.Feedback type="invalid">
            {serverErrors.name?.msg || ''}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Type your new email"
            name="email"
            onInput={form.handleChangeEvent}
            defaultValue={fields.email}
            isInvalid={errors.email || serverErrors.email}
          />

          <Form.Control.Feedback type="invalid">
            {errors.email?.replace('email', 'email') || ''}
          </Form.Control.Feedback>

          <Form.Control.Feedback type="invalid">
            {serverErrors.email?.msg || ''}
          </Form.Control.Feedback>
        </Form.Group>

        <Button type="submit">Submit</Button>
      </Form>
    </>
  );
}
