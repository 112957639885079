import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import dash_icon_01 from './../../static/images/dash_icon_01.svg';
import dash_icon_02 from './../../static/images/dash_icon_02.svg';
import arrow_right from './../../static/images/arrow_right.svg';
import link from './../../static/images/link.svg';
import helper from '../../utils/helper';
import Loader from '../../components/commons/Loader';

const DashboardNew = () => {
  const [isLoaded, setLoaded] = useState(false);

  const [statData, setStatData] = useState({
    activeSubscription: 0,
    totalUrlScans: 0,
    maliciousUrl: 0,
    urlChecks: [],
    totalCustomer: 0,
    totalSales: 0,
    extensionInstallationCount: 0,
  });

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    const response = await helper.sendApiRequest('GET', 'dashboard');
    const data = await response.json();

    if ('success' in data && data.success) {
      setLoaded(true);
      setStatData(data.data);
    } else {
    }
  };

  return (
    <>
      {!isLoaded ? (
        <Loader />
      ) : (
        <>
          <div className="topHead">
            <h3>Dashboard</h3>
          </div>
          <div className="boxVr">
            <ul className="vwmore">
              <li>
                <div className="detailBox">
                  <div className="numbring">
                    <h1>{statData.totalCustomer}</h1>
                    <img src={dash_icon_01} alt="dash_icon_01" className="img-fluid" />
                  </div>
                  <div className="smallBtm">
                    <h4>Total Active Customer</h4>
                    <Link to="/customer">
                      View All{' '}
                      <img src={arrow_right} alt="arrow_right" className="img-fluid arrow" />
                    </Link>
                  </div>
                </div>
              </li>
              <li>
                <div className="detailBox">
                  <div className="numbring">
                    <h1>{statData.activeSubscription}</h1>
                    <img src={dash_icon_02} alt="dash_icon_02" className="img-fluid" />
                  </div>
                  <div className="smallBtm">
                    <h4>Total Subscriptions</h4>
                    <a href="#">
                      View All{' '}
                      <img src={arrow_right} alt="arrow_right" className="img-fluid arrow" />
                    </a>
                  </div>
                </div>
              </li>

              <li>
                <div className="detailBox">
                  <div className="numbring">
                    <h1>{statData.extensionInstallationCount}</h1>
                    <img src={dash_icon_01} alt="dash_icon_01" className="img-fluid" />
                  </div>
                  <div className="smallBtm">
                    <h4>Total Extension Installation</h4>
                    <a href="#">
                      View All{' '}
                      <img src={arrow_right} alt="arrow_right" className="img-fluid arrow" />
                    </a>
                  </div>
                </div>
              </li>

              {/*  <li>
                            <div className='detailBox'>
                                <div className='numbring'>
                                    <h1>$3000</h1>
                                    <img src={dash_icon_03} alt='dash_icon_03' className='img-fluid' />
                                </div>
                                <div className='smallBtm'>
                                    <h4>Total Sales</h4>
                                    <a href='#'>View All  <img src={arrow_right} alt='arrow_right' className='img-fluid arrow' /></a>
                                </div>
                            </div>
                        </li> */}
            </ul>
            {/* Latest url Section */}
            <div className="lateBox">
              <Row>
                <Col sm={6}>
                  <h2>Malicious URL(s)</h2>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                    unknown printer took a galley of type and scrambled it to make a type specimen
                    book.
                  </p>

                  <div className="linkBdy">
                    <img src={link} alt="link" className="img-fluid" />
                    <div className="totl">
                      <h1>{statData.maliciousUrl}</h1>
                      <p>Total Malicious URL(s)</p>
                      <Link to="/urls?malicious=1">
                        View All{' '}
                        <img src={arrow_right} alt="arrow_right" className="img-fluid arrow" />
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="recentTable">
                    <div className="view">
                      <div className="rlu">
                        <h2>Latest URL(s) List</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipis.</p>
                      </div>
                      <div className="righticon">
                        <Link to="/urls" clLinkssName="">
                          View All{' '}
                          <img src={arrow_right} alt="arrow_right" className="img-fluid arrow" />
                        </Link>
                      </div>
                    </div>
                    <div className="tableView">
                      <table className="table table-hover">
                        <tbody>
                          <tr>
                            <td className="hdtbl">#</td>
                            <td className="hdtbl">Name</td>
                            <td align="right" className="hdtbl">
                              <span className="pdR20"> Date </span>
                            </td>
                          </tr>
                          {statData.urlChecks.map((item, index) => {
                            let isMal = item.isMalicious;
                            return (
                              <tr key={index}>
                                <td className="mdCls">{index + 1}</td>
                                <td className={isMal ? 'mdCls clrRed' : 'mdCls clrGrn'}>
                                  {item.domain}
                                </td>
                                <td align="right" className="lightfont">
                                  {new Date(item.createdDateTime).toDateString()}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            {/* Latest url Section */}
            {/*  <div className='analy'>
                        <Row>
                            <Col sm={6}>
                                <div className='reportBox'>
                                    <h2>Total Analytics Report</h2>
                                    <div className='chart'>
                                        <img src={chart} alt='chart' className='' />
                                    </div>
                                    <ul className='circlList'>
                                        <li>Malicious URL</li>
                                        <li>Safe URL</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col sm={6}></Col>
                        </Row>
                    </div> */}
          </div>
        </>
      )}
    </>
  );
};

export default DashboardNew;
