import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useFormInputValidation } from 'react-form-input-validation';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';
import { setToast } from '../../slices/toastMessageSlice';
import { useDispatch } from 'react-redux';
import config from '../../utils/config';
import helper from './../../utils/helper';

const EditCampaign = ({ campaign, updateCmapaign, closeEditModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [serverErrors, setServerErrors] = useState({});

  const [fields, errors, form] = useFormInputValidation(
    {
      sourceDomain: campaign.sourceDomain,
      destinationDomain: campaign.destinationDomain,
      notificationTitle: campaign.notificationTitle,
      notificationBody: campaign.notificationBody,
      iconImage: '',
    },
    {
      sourceDomain: 'required|url',
      destinationDomain: 'required|url',
      notificationTitle: 'required',
      notificationBody: 'required',
      iconImage: 'required',
    },
  );

  const onSubmit = async (event) => {
    const isValid = await form.validate(event);

    if (isValid) {
      const formData = new FormData(document.getElementById('editCampaignForm'));
      const respone = await helper.sendApiRequest('POST', `campaign/${campaign._id}`, formData);
      const result = await respone.json();
      if (result.success) {
        navigate(0);
        dispatch(
          setToast({
            type: 'success',
            message: result.message ? result.nessage : 'Campaign updated',
            title: 'Success',
          }),
        );
      } else {
        if (result.errors) {
          setServerErrors(result.errors);
        } else {
          // alert('Unable to update')
          dispatch(
            setToast({
              type: 'danger',
              message: result.message ? result.message : 'Unable to process',
              title: 'Error',
            }),
          );
          closeEditModal();
        }
      }
    }
  };

  return (
    <>
      <Modal
        show={true}
        // onHide={(handleClose)}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Container className="mt-4 mb-2">
          <h3>Edit Campaign</h3>
          <form noValidate onSubmit={onSubmit} id="editCampaignForm" encType="multipart/form-data">
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="exampleForm.sourceDomain">
                  <Form.Label>Source</Form.Label>
                  <Form.Control
                    type="text"
                    name="sourceDomain"
                    onInput={form.handleChangeEvent}
                    defaultValue={fields.sourceDomain}
                    isInvalid={errors.sourceDomain || serverErrors.sourceDomain}
                  />
                  <Form.Text className="text-muted">
                    Fully qualified domain e.g. https://www.google.com
                  </Form.Text>

                  <Form.Control.Feedback type="invalid">
                    {errors.sourceDomain?.replace('sourceDomain', 'source') || ''}
                  </Form.Control.Feedback>

                  <Form.Control.Feedback type="invalid">
                    {serverErrors.sourceDomain?.msg || ''}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3" controlId="exampleForm.destinationDomain">
              <Form.Label>Destination</Form.Label>
              <Form.Control
                type="text"
                name="destinationDomain"
                onInput={form.handleChangeEvent}
                defaultValue={fields.destinationDomain}
                isInvalid={errors.destinationDomain || serverErrors.destinationDomain}
              />
              <Form.Text className="text-muted">
                Fully qualified domain e.g. https://www.google.com
              </Form.Text>

              <Form.Control.Feedback type="invalid">
                {errors.destinationDomain?.replace('destinationDomain', 'destination') || ''}
              </Form.Control.Feedback>

              <Form.Control.Feedback type="invalid">
                {serverErrors.destinationDomain?.msg || ''}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.notificationTitle">
              <Form.Label>Notification title</Form.Label>
              <Form.Control
                type="text"
                name="notificationTitle"
                defaultValue={fields.notificationTitle}
                onInput={form.handleChangeEvent}
                isInvalid={errors.notificationTitle || serverErrors.notificationTitle}
              />

              <Form.Text className="text-muted">e.g. Title</Form.Text>

              <Form.Control.Feedback type="invalid">
                {errors.notificationTitle?.replace('notificationTitle', 'title') || ''}
              </Form.Control.Feedback>

              <Form.Control.Feedback type="invalid">
                {serverErrors.notificationTitle?.msg || ''}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.notificationBody">
              <Form.Label>Notification body</Form.Label>
              <Form.Control
                type="text"
                name="notificationBody"
                defaultValue={fields.notificationBody}
                onInput={form.handleChangeEvent}
                isInvalid={errors.notificationBody || serverErrors.notificationBody}
              />

              <Form.Text className="text-muted">e.g. Body</Form.Text>

              <Form.Control.Feedback type="invalid">
                {errors.notificationBody?.replace('notificationBody', 'body') || ''}
              </Form.Control.Feedback>

              <Form.Control.Feedback type="invalid">
                {serverErrors.notificationBody?.msg || ''}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group /* controlId="file" */ className="mb-3">
              <Form.Label>Icon Image</Form.Label>
              <Form.Control
                type="file"
                accept="image/png, image/jpeg"
                onInput={form.handleChangeEvent}
                isInvalid={errors.iconImage || serverErrors.iconImage}
                defaultValue={fields.iconImage}
                required={true}
                name="iconImage"
                id="iconImage"
              />
              <Form.Text className="text-muted">PNG, JPEG images upto 1 MB.</Form.Text>
              <Form.Control.Feedback type="invalid">
                {errors.iconImage?.replace('iconImage', 'icon') || ''}
              </Form.Control.Feedback>

              <Form.Control.Feedback type="invalid">
                {serverErrors.iconImage?.msg || ''}
              </Form.Control.Feedback>
              <br />
              <Form.Text>
                <img src={`${config.baseApiUrl}uploads/${campaign.iconImage}`} width={50} />
              </Form.Text>
            </Form.Group>
            <div className="editCamp">
              <Button type="submit" className="mr-10">
                Update{' '}
              </Button>
              <Button type="button" variant="secondary" onClick={closeEditModal}>
                Cancel
              </Button>
            </div>
          </form>
        </Container>
      </Modal>
    </>
  );
};

export default EditCampaign;
