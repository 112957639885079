import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { useEffect, useState } from 'react';
import helper from '../utils/helper';
import { useFormInputValidation } from 'react-form-input-validation';
import Loader from '../components/commons/Loader';
import JSONPretty from 'react-json-pretty';

const SiteInfoPage = () => {
  const [isLoading, setLoading] = useState(false);
  const [websiteInfo, setWebsiteInfo] = useState({});

  const [siteUrl] = useState(() => {
    const url = new URL(window.location.href);
    const searchingUrl = url.searchParams.get('url');
    return searchingUrl;
  });

  useEffect(() => {
    if (siteUrl != '') {
      getData(siteUrl);
    }
  }, []);

  const [fields, errors, form] = useFormInputValidation(
    {
      url: '',
    },
    {
      url: 'required|url',
    },
  );

  const onSubmit = async (event) => {
    const isValid = await form.validate(event);
    if (isValid) {
      getData(fields.url);
    }

    helper.sendApiRequest('GET', 'site-info/report', {
      url: siteUrl,
    });
  };
  const getData = async (url) => {
    setLoading(true);
    const respone = await helper.sendApiRequest('GET', 'site-info/report', {
      url: url,
    });
    const result = await respone.json();
    setLoading(false);
    if (result.success) {
      setWebsiteInfo(result.websiteInfo);
    }
  };

  return (
    <>
      <Form noValidate onSubmit={onSubmit} id="searchForm">
        <Row>
          <Col>
            <Form.Control
              type="text"
              name="url"
              onChange={form.handleChangeEvent}
              isInvalid={errors.sourceDomain}
              defaultValue={siteUrl}
            />
            <Form.Control.Feedback type="invalid">
              {errors.sourceDomain || ''}
            </Form.Control.Feedback>
          </Col>
          <Col>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>

      {isLoading ? (
        <Loader />
      ) : (
        /*  <Card >
                <Card.Header>Details</Card.Header>
                {
                    siteInfo ?
                        <ListGroup variant="flush">
                            <ListGroup.Item>Domain: {siteInfo.domain} </ListGroup.Item>
                            <ListGroup.Item>Domain Ip: {siteInfo.domainIpAddress}</ListGroup.Item>
                            <ListGroup.Item>Domain Rank: {siteInfo.domainRank} </ListGroup.Item>
                            <ListGroup.Item>Is Safe?: {siteInfo.isSafe ? 'Yes': 'No'} </ListGroup.Item>
                            <ListGroup.Item>DNS Valid? {siteInfo.isDnsValid ? 'Yes': 'No'}  </ListGroup.Item>
                            <ListGroup.Item>DoIs Parking?: {siteInfo.isParking ? 'Yes': 'No'}  </ListGroup.Item>
                            <ListGroup.Item>Is Spam? {siteInfo.isSpamming ? 'Yes': 'No'}  </ListGroup.Item>
                            <ListGroup.Item>Is Malware?:  {siteInfo.isMalware ? 'Yes': 'No'}  </ListGroup.Item>
                            <ListGroup.Item>Is Phising?: {siteInfo.isPhishing ? 'Yes': 'No'}  </ListGroup.Item>
                            <ListGroup.Item>Is Suspecios?: {siteInfo.isSuspicious ? 'Yes': 'No'}  </ListGroup.Item>
                            <ListGroup.Item>Is Adult?: {siteInfo.isAdult ? 'Yes': 'No'}  </ListGroup.Item>
                            <ListGroup.Item>Is Dangeorous: {siteInfo.isDangerous ? 'Yes': 'No'} </ListGroup.Item>
                            <ListGroup.Item>Category {siteInfo.category}</ListGroup.Item>
                            <ListGroup.Item>Risk {siteInfo.riskScore}</ListGroup.Item>
                        </ListGroup>
                    :
                    <ListGroup variant="flush">
                        <ListGroup.Item>We are unable to fget site information </ListGroup.Item>
                    </ListGroup>

                }


            </Card> */
        <>
          websiteInfo: <JSONPretty id="json-pretty" data={websiteInfo} />
        </>
      )}
    </>
  );
};

export default SiteInfoPage;
