import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { removeUser } from '../slices/loggedInUserSlice';
import { useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
export default function Sidebar(props) {
  const location = useLocation();
  // const pathname = location.pathname;
  const [pathname, setPathname] = useState(location.pathname)
  const params = useParams()

  const dispatch = useDispatch()
  const logout = () => {
    dispatch(removeUser())
  }
  useEffect(() => {
    setPathname(location.pathname)
  }, [location])
  return (
    <>
      <div className='sidebar'>
        <div className="sideMenu">
          {/* <Nav defaultActiveKey="/home" className="flex-column">
        <Nav.Link href="/dashboard">
          <span className="menuTitle">Home</span>
        </Nav.Link>
        <Nav.Link href="/home">
          <span className="menuTitle">Setting</span>
        </Nav.Link>
      </Nav> */}
          <ul>
            <li className={pathname == '/' ? 'active': ''}><Link to='/'>Dashboard</Link></li>
            {/* <li><Link to='/dashboard-new'>Dashboard New</Link></li> */}
            <li  className={pathname == '/campaign' ? 'active': ''}>
              <Link to='/campaign'>
                Campaign
              </Link>
            </li>
            <li  className={pathname == '/settings' ? 'active': ''}><Link to='/settings'>Settings</Link></li>
            <li className={pathname == '/customer' ? 'active': ''}><Link to='/customer'  >Customers</Link></li>
            <li className={pathname == '/urls' ? 'active': ''}><Link to='/urls' >Urls</Link></li>

            {/* <li><Link to='/active_ustomers'>Active Customers</Link></li>
            <li><Link to='/active_subscription'>Active Subscription</Link></li>
            <li><Link to='/malicious_url_Report'>Malicious URL Report</Link></li>
            <li><Link to='/total_url_scan'>Total URL Scan</Link></li>
            <li><Link to='/analytics_report'>Analytics Report</Link></li>
            <li><Link to='/total_sales_report'>Total Sales Report</Link></li> */}

            {/* <li><Link onClick={logout}>Logout</Link></li> */}

          </ul>
        </div>
      </div>

    </>
  )
}