import { useState, useEffect, useRef } from 'react';
import Loader from '../../components/commons/Loader';
import Table from 'react-bootstrap/Table';
import helper from '../../utils/helper';
import Paginate from './../../components/commons/Paginate';
import { Send, XLg } from 'react-bootstrap-icons';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import moment from 'moment';
import { setToast } from '../../slices/toastMessageSlice';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import * as yup from 'yup';

const CustomerListPage = () => {
  const dispatch = useDispatch();

  const fileInputRef = useRef(null);
  const [masterData, setMastarData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoaded, setLoaded] = useState(false);
  const [activeCustomer, setActiveCustomer] = useState(null);
  const [imageSrc, setImageSrc] = useState('');
  const [config, setConfig] = useState({});
  const [checkedList, setCheckedList] = useState([])

  const getConfig = async () => {
    try {
      const res = await helper.sendApiRequest('GET', 'setting');
      const response = await res.json();

      const resConfig = response.configs.reduce((acc, conf) => {
        acc[conf.key] = conf.value;
        return acc;
      }, {});

      return resConfig;
    } catch (error) {
      console.log('error from getting setting data: ', error);
    }
  };

  useEffect(() => {
    getConfig().then((res) => {
      setConfig(res)
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = async () => {
    const response = await helper.sendApiRequest('GET', 'customers', { page: page });
    const data = await response.json();

    if ('success' in data && data.success) {
      setMastarData(data.customerData);
      setLoaded(true);
      setTotalPage(data.totalPages);
    }
  };

  const toggleModal = (customerData) => {
    setActiveCustomer(customerData);
  };

  const handleCheckChange = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setCheckedList((prev) => [...prev, value])
    } else {
      setCheckedList((prev) => [...prev.filter((item) => item !== value)])
    }
  }

  const handleAllCheckChange = (event) => {
    if (event.target.checked) {
      setCheckedList(masterData.map(({ _id }) => _id))
    } else {
      setCheckedList([])
    }
  }

  const toggleDeactiveSwitch = async (event) => {
    const id = event.target.value;
    const accountStatus = event.target.checked;

    const response = await helper.sendApiRequest('POST', `customers/change-status/${id}`, { accountStatus });
    const data = await response.json();

    if ('success' in data && data.success) {
      dispatch(
        setToast({
          type: 'primary',
          message: data.message,
          title: 'Success',
        }),
      );
      await fetchData();
    } else {
      dispatch(
        setToast({
          type: 'danger',
          message: data.message,
          title: 'Error',
        }),
      );
    }
  }

  const pushNotificationSchema = yup.object().shape({
    notificationTitle: yup.string().required('Notification title is required.'),
    notificationBody: yup.string().required('Notification body is required.'),
    notificationIcon: yup
      .mixed()
      .test('fileType', 'Invalid file type. Only images are allowed.', (value) => {
        if (!value) return true; // Allow empty value
        return value && ['image/png', 'image/jpeg'].includes(value.type);
      })
      .test('fileSize', 'File size must be less than or equal to 1 MB.', (value) => {
        if (!value) return true; // Allow empty value
        return value && value.size <= 1024 * 1024; // 1 MB in bytes
      }),
    notificationRedirectUrl: yup.string().required('Notification redirect URL is required.'),
    sheduleSend: yup.boolean(),
    scheduleDateTime: yup
      .string()
      .when('sheduleSend', {
        is: (value) => value === true,
        then: () => yup.string()
          // .min(new Date().toISOString().slice(0, -8), 'Scheduled date and time must be in the future')
          .required('Scheduled date and time are required.'),
      }),
  });

  const submitValidForm = async (values) => {
    const dateObj = values.sheduleSend ? new Date(values.scheduleDateTime) : new Date();
    const dateUtc = dateObj.toISOString();
    const formValues = {
      ids: checkedList.join(','), ...values,
      ...(values.sheduleSend && { scheduleDateTime: dateUtc }),
      ...{ completed: !values.sheduleSend }
    };

    // Assuming values is your object containing key-value pairs
    const formData = new FormData();

    // Iterate over the entries of the object
    Object.entries(formValues).forEach(([key, value]) => {
      // Append each key-value pair to the FormData
      formData.append(key, value);
    });

    const response = await helper.sendApiRequest('POST', `customers/push-notification`, formData);
    const data = await response.json();

    if ('success' in data && data.success) {
      dispatch(
        setToast({
          type: 'primary',
          message: data.message,
          title: 'Success',
        }),
      );

      toggleModal(null)
    } else {
      dispatch(
        setToast({
          type: 'danger',
          message: data.message,
          title: 'Error',
        }),
      );
    }
  }

  const { values, errors, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      notificationTitle: '',
      notificationBody: '',
      notificationIcon: '',
      notificationRedirectUrl: '',
      sheduleSend: false,
      scheduleDateTime: ''
    },
    validationSchema: pushNotificationSchema,
    onSubmit: submitValidForm,
  });


  const handleChangeFile = (event) => {
    const file = event.target.files[0] || ''; // Get the first file from the input
    setFieldValue('notificationIcon', file)

    if (file && ['image/png', 'image/jpeg'].includes(file.type)) {
      // File type is an image
      const previewSrc = URL.createObjectURL(file); // Create preview source
      setImageSrc(previewSrc);
    } else {
      // File type is not an image
      setImageSrc('');
      // Optionally, you can display an error message to the user or perform other actions.
    }
  }

  const handleSetDefaultIcon = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset the value of the input to null
    }
    setImageSrc('');
    setFieldValue('notificationIcon', '')
  }


  return (
    <>
      {!isLoaded ? (
        <Loader />
      ) : (
        <>
          <div className="topHead">
            <h3>Customers</h3>
            <Button type="button"
              disabled={!checkedList.length}
              onClick={() => toggleModal(checkedList)}>
              <Send
                size={20}
              />
              {' '}Push Notification
            </Button>
          </div>
          <Table striped bordered hover size="sm" responsive>
            <thead>
              <tr>
                <th>
                  <Form.Check
                    name="select"
                    type="checkbox"
                    className='p-2'
                    onChange={handleAllCheckChange}
                    checked={checkedList.length === masterData.length}
                  />
                </th>
                <th>Email</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Type</th>
                <th>Expiry Date</th>
                <th>Install Date</th>
                <th>Ip</th>
                <th>Browser</th>
                <th>Location</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {masterData.length > 0
                ? masterData.map((item) => {
                  return (
                    <tr key={item._id}>
                      <td>
                        <Form.Check
                          name="checks"
                          type="checkbox"
                          className='p-2'
                          value={item._id}
                          onChange={handleCheckChange}
                          checked={checkedList.includes(item._id)}
                        />
                      </td>
                      <td>{item.email}</td>
                      <td>{item.name}</td>
                      <td>{item.phoneNumber || 'N/A'}</td>
                      <td>{item.userType}</td>
                      <td>{moment(item.expiryDate).format("YYYY-MM-DD hh:mm:ss A")}</td>
                      <td>{moment(item.createdDateTime).format("YYYY-MM-DD hh:mm:ss A")}</td>
                      <td>{item.signupIpAddress}</td>
                      <td>{item.signupBrowser}</td>
                      <td>{item.signupLocation.coordinates.toString() || "N/A"}</td>
                      <td className="text-danger">
                        <Form.Check // prettier-ignore
                          type="switch"
                          title='Deactive User'
                          value={item._id}
                          checked={item.accountStatus}
                          onChange={toggleDeactiveSwitch}
                        />
                      </td>
                    </tr>
                  );
                })
                : null}
            </tbody>
          </Table>
          <Paginate totalPage={totalPage} pageChangeHandler={setPage} currentPage={page} />

          {activeCustomer ? (
            <Modal show={true} className="modalPopup" backdrop="static" keyboard={false} size="lg" centered>
              <Modal.Dialog className="dilogWrap">
                <Modal.Header closeButton onClick={() => toggleModal(null)}>
                  <Modal.Title>Customer Details</Modal.Title>
                </Modal.Header>

                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Body>
                    <Form.Group className="mb-3" controlId="exampleForm.NotificationTitle">
                      <Form.Label>Notification Title</Form.Label>
                      <Form.Control type="text"
                        placeholder="Enter Title"
                        value={values.notificationTitle}
                        onChange={handleChange}
                        name='notificationTitle'
                        isInvalid={Boolean(errors.notificationTitle)} />
                      <Form.Control.Feedback type="invalid">
                        {errors.notificationTitle || ''}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.NotificationBody">
                      <Form.Label>Notification Body</Form.Label>
                      <Form.Control as="textarea" rows={3} placeholder='Type Body Here...'
                        value={values.notificationBody}
                        onChange={handleChange}
                        name='notificationBody'
                        isInvalid={Boolean(errors.notificationBody)} />
                      <Form.Control.Feedback type="invalid">
                        {errors.notificationBody || ''}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Row>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="exampleForm.NotificationIcon">
                          <Form.Label>Notification Icon (optional)</Form.Label>
                          <Form.Control type="file" onChange={handleChangeFile} accept='image/*' ref={fileInputRef}
                            isInvalid={Boolean(errors.notificationIcon)}
                            style={{
                              backgroundImage: 'none'
                            }} />
                          {fileInputRef.current?.value && (
                            <XLg size={20} style={{
                              position: 'relative',
                              bottom: '30px',
                              left: '345px',
                              color: '#6f262d',
                              cursor: 'pointer'
                            }}
                              onClick={handleSetDefaultIcon}
                            />
                          )}
                          <Form.Text muted>
                            Accepted formats: PNG & JPEG. Max size: 1 MB.
                          </Form.Text>
                          <Form.Control.Feedback type="invalid">
                            {errors.notificationIcon || ''}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Image src={imageSrc || config.defaultNotificationIcon} thumbnail width="80px" height="80px" />
                      </Col>
                    </Row>
                    <Form.Group className="mb-3" controlId="exampleForm.RedirectUrl">
                      <Form.Label>Redirect Url</Form.Label>
                      <Form.Control type="url" placeholder='Enter Redirect Url'
                        value={values.notificationRedirectUrl}
                        onChange={handleChange}
                        name='notificationRedirectUrl'
                        isInvalid={Boolean(errors.notificationRedirectUrl)} />
                      <Form.Control.Feedback type="invalid">
                        {errors.notificationRedirectUrl || ''}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Check
                      type="checkbox"
                      label="Schedule Send"
                      onChange={handleChange}
                      name='sheduleSend'
                      checked={values.sheduleSend}
                    />
                    {values.sheduleSend && (
                      <Form.Group className="mb-3" controlId="exampleForm.NotificationTitle">
                        <Form.Label>Schedule Time</Form.Label>
                        <Form.Control
                          type="datetime-local"
                          value={values.scheduleDateTime}
                          onChange={handleChange}
                          name='scheduleDateTime'
                          min={new Date().toISOString().slice(0, -8)}
                          isInvalid={Boolean(errors.scheduleDateTime)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.scheduleDateTime || ''}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" type='submit'>Send</Button>
                  </Modal.Footer>
                </Form>
              </Modal.Dialog>
            </Modal>
          ) : null}
        </>
      )}
    </>
  );
};

export default CustomerListPage;
