import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { useFormInputValidation } from 'react-form-input-validation';
import Button from 'react-bootstrap/Button';
import helper from '../../utils/helper';
import { setToast } from '../../slices/toastMessageSlice';
import { useDispatch } from 'react-redux';

const ApiKeysForm = ({ defaultValues }) => {
  // const [isLoading, setLoading] = useState(true)
  // const [formValues, setFormValues] = useState({})
  const [serverErrors, setServerErrors] = useState({});
  const dispatch = useDispatch();

  const [fields, errors, form] = useFormInputValidation(
    {
      ipqualityscoreApiKey: defaultValues.ipqualityscoreApiKey,
      virusTotalApiKey: defaultValues.virusTotalApiKey,
    },
    {
      ipqualityscoreApiKey: 'required',
      virusTotalApiKey: 'required',
    },
  );

  const onSubmit = async (event) => {
    const isValid = await form.validate(event);
    if (isValid) {
      const respone = await helper.sendApiRequest('POST', 'setting/api-keys', fields);
      const result = await respone.json();
      if (result.success) {
        dispatch(
          setToast({
            type: 'primary',
            message: result.message,
            title: 'Success',
          }),
        );
      } else {
        if (result.errors) {
          setServerErrors(result.errors);
        } else {
          dispatch(
            setToast({
              type: 'danger',
              message: result.message,
              title: 'Error',
            }),
          );
        }
      }
    }
  };

  return (
    <>
      <Form noValidate onSubmit={onSubmit} id="createCampaignForm">
        <Form.Group className="mb-3" controlId="exampleForm.ipqualityscoreApiKey">
          <Form.Label>IP Quality Score Api Key</Form.Label>
          <Form.Control
            type="text"
            name="ipqualityscoreApiKey"
            onInput={form.handleChangeEvent}
            onChange={form.handleChangeEvent}
            defaultValue={fields.ipqualityscoreApiKey}
            isInvalid={errors.ipqualityscoreApiKey || serverErrors.ipqualityscoreApiKey}
          />

          <Form.Text className="text-muted">IP Quality Score Api Key</Form.Text>

          <Form.Control.Feedback type="invalid">
            {errors.ipqualityscoreApiKey?.replace(
              'ipqualityscoreApiKey',
              'Ip Quality score api key',
            ) || ''}
          </Form.Control.Feedback>

          <Form.Control.Feedback type="invalid">
            {serverErrors.ipqualityscoreApiKey?.msg || ''}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.virusTotalApiKey">
          <Form.Label>Virus Total Api Key</Form.Label>
          <Form.Control
            type="text"
            name="virusTotalApiKey"
            onInput={form.handleChangeEvent}
            defaultValue={fields.virusTotalApiKey}
            isInvalid={errors.virusTotalApiKey || serverErrors.virusTotalApiKey}
          />

          <Form.Text className="text-muted">Virus Total Api Key</Form.Text>

          <Form.Control.Feedback type="invalid">
            {errors.virusTotalApiKey?.replace('virusTotalApiKey', 'Virus Total Api Key') || ''}
          </Form.Control.Feedback>

          <Form.Control.Feedback type="invalid">
            {serverErrors.virusTotalApiKey?.msg || ''}
          </Form.Control.Feedback>
        </Form.Group>

        <Button type="submit">Submit</Button>
      </Form>
    </>
  );
};

export default ApiKeysForm;
