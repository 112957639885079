import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Sidebar from "./Sidebar";
// import CampaignList from "../components/campaign/CampaignList";
import Header from "./Header/Header";

import { useNavigate } from 'react-router-dom';

import { selectLoggedInUserToken } from "../slices/loggedInUserSlice";
import { useSelector } from 'react-redux'
import { useEffect } from "react";
import RightPanel from "./RightPanel";

const IndexLayout = () => {
    const user = useSelector(selectLoggedInUserToken)
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            return navigate('/login')
        }
    }, [user])



    return <>
        <Header />
        <div className="manageLayout">
            <Sidebar />
            <RightPanel >
                <Outlet />
            </RightPanel>
        </div>

    </>
}

export default IndexLayout;