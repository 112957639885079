import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UpdatePasswordForm from '../../components/profile/UpdatePasswordForm';
import ProfileForm from '../../components/profile/ProfileForm';

const ProfilePage = () => {
  return (
    <>
      {
        <>
          <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="profile" title="Update Profile ">
              <ProfileForm />
            </Tab>

            <Tab eventKey="password" title="Update Password ">
              <UpdatePasswordForm />
            </Tab>
          </Tabs>
          {/*   */}
        </>
      }
    </>
  );
};

export default ProfilePage;
