import * as React from "react";
import IndexLayout from "./layouts/IndexLayout";
import GuestLayout from "./layouts/GuestLayout";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import DashboardNew from "./pages/Dashboard/DashboardNew";
import CreateCampaign from "./pages/campaign/CreateCampaign";
import CapaignList from "./pages/campaign/CampaignList";
import CustomerListPage from "./pages/customer/CustomerListPage";
import SiteInfoPage from "./pages/SiteInfoPage";
import ClientLogin from "./pages/ClientLogin";
import SettingPage from "./pages/SettingPage";
import Login from "./pages/Login/Login";
import ForgotPassword from "./pages/Login/ForgotPassword";
import WebsiteListPage from "./pages/websites/WebsiteListPage";
import ProfilePage from "./pages/profile/ProfilePage";
import "./style.css";
import ToastComponent from "./components/commons/ToastComponent";

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <IndexLayout />,
      children: [
        { path: "/", element: <DashboardNew /> },
        { path: "/campaign", element: <CapaignList /> },
        { path: "/customer", element: <CustomerListPage /> },
        { path: "/campaign/create", element: <CreateCampaign /> },
        { path: "/site-info", element: <SiteInfoPage /> },
        { path: "/settings", element: <SettingPage /> },
        { path: "/urls", element: <WebsiteListPage /> },
        { path: "/profile", element: <ProfilePage /> },
      ],
    },
    {
      path: "/",
      element: <GuestLayout />,
      children: [
        { path: "/login", element: <Login /> },
        { path: "/forgot-password", element: <ForgotPassword /> },
      ],
    },
    {
      path: "/client-login",
      element: <ClientLogin />,
    },
  ],
);

function App() {
  return (
    <>
      <RouterProvider router={router} />
      <ToastComponent />
    </>
  );
}

export default App;
