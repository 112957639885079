import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { useFormInputValidation } from 'react-form-input-validation';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
// import helper from '../../utils/helper';
import helper from '../utils/helper';
import logo from './../static/images/logo.svg';

const saveToken = async (extensionId, jwt) => {
  function detectBrowser() {
    var browser = navigator.userAgent;

    if (/Chrome/.test(browser)) {
      return 'Chrome';
    } else if (/Firefox/.test(browser)) {
      return 'Firefox';
    } else if (/Safari/.test(browser)) {
      return 'Safari';
    } else if (/Edge/.test(browser)) {
      return 'Edge';
    } else {
      return 'Unknown';
    }
  }

  const browser = detectBrowser();
  // console.log({ browser: browser });
  if (['Chrome', 'Edge'].includes(browser)) {
    if ('chrome' in window && window.chrome) {
      // console.log(13, extensionId);
      // chrome and edge have same userAgent. so the browsere detecxtion ight not work properly for edge
      // so make sure to fire the event for both browser

      const res = await window.chrome.runtime.sendMessage(extensionId, {
        token: jwt,
      });

      window.close();
    } else {
      alert("Your browser isn't supported");
      // console.log('chrome is not defined')
    }
  } else if (['Firefox'].includes(browser)) {
    // console.log({ Firefox: 'Firefox' });
    var event = new CustomEvent('saveLoginToken', {
      bubbles: true,
      detail: {
        token: jwt,
      },
    });
    window.dispatchEvent(event);
    window.close();
  }
};

const ClientLogin = () => {
  // const navigate = useNavigate();
  const [serverErrors, setServerErrors] = useState({});

  // const [formData, setFormDta] = useState({
  //     sourceDomain: '',
  //     destinationDomain: '',
  //     notificationTitle: '',

  //     notificationBody: '',
  //     iconImage: '',
  // })

  const [fields, errors, form] = useFormInputValidation(
    {
      email: '',
      password: '',
      extId: '',
    },
    {
      email: 'required|email',
      password: 'required',
      extId: 'required',
    },
  );

  // const formChangeHandler = (event) => {
  //     setFormDta(({
  //         ...formData,
  //         ...{
  //             [event.target.name]: event.target.name
  //         }
  //     }))
  // }

  const onSubmit = async (event) => {
    const isValid = await form.validate(event);

    if (isValid) {
      const respone = await helper.sendApiRequest('POST', 'auth/client-login/', fields);
      const result = await respone.json();
      if (result.success) {
        // navigate('/')

        const extensionId = result.extId;
        const token = result.token;

        saveToken(extensionId, token);
      } else {
        if (result.errors) {
          setServerErrors(result.errors);
        } else {
          alert('Unable to update');
        }
      }
    }
  };

  return (
    <>
      <div className="login">
        <ul>
          <li>
            <a href="#">
              {' '}
              <img src={logo} alt="logo" />
            </a>
          </li>
        </ul>
        <div className="loginBox">
          <form
            noValidate
            onSubmit={onSubmit}
            id="createCampaignForm"
            encType="multipart/form-data"
          >
            <Form.Group className="clLogin" controlId="exampleForm.email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                name="email"
                onChange={form.handleChangeEvent}
                value={fields.email}
                isInvalid={errors.email || serverErrors.email}
              />
              <Form.Text className="text-muted">Your email</Form.Text>

              <Form.Control.Feedback type="invalid">{errors.email || ''}</Form.Control.Feedback>

              <Form.Control.Feedback type="invalid">
                {serverErrors.email?.msg || ''}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                onChange={form.handleChangeEvent}
                isInvalid={errors.password || serverErrors.password}
              />
              <Form.Text className="text-muted">Your password</Form.Text>

              <Form.Control.Feedback type="invalid">
                {errors.password?.replace('password', 'destination') || ''}
              </Form.Control.Feedback>

              <Form.Control.Feedback type="invalid">
                {serverErrors.password?.msg || ''}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.extId">
              <Form.Label>Chrome extension id</Form.Label>
              <Form.Control
                type="text"
                name="extId"
                onChange={form.handleChangeEvent}
                isInvalid={errors.extId || serverErrors.extId}
              />

              <Form.Control.Feedback type="invalid">
                {errors.extId?.replace('extId', 'extension id') || ''}
              </Form.Control.Feedback>

              <Form.Control.Feedback type="invalid">
                {serverErrors.extId?.msg || ''}
              </Form.Control.Feedback>
            </Form.Group>

            <Button type="submit">Submit</Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ClientLogin;
