import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ConfirmationModal({message, modalHandler, confirmHandler}) {

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const handleClose = () => {
    modalHandler(false)
  }
  return (
    <>
      <Modal
        show={true}
        onHide={(handleClose)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         {message}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmHandler}>Understood</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmationModal;
