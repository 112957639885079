import { useState, useEffect } from 'react';
import helper from '../utils/helper';
import Spinner from 'react-bootstrap/Spinner';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import ApiIntegrationForm from '../components/settings/ApiIntegrationForm';
import CustomerSettingsForm from '../components/settings/CustomerSettingsForm';
import ApiKeysForm from '../components/settings/ApiKeysForm';

const SettingPage = () => {
  const [isLoading, setLoading] = useState(true);
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    getConfig();
  }, []);

  const getConfig = async () => {
    try {
      const res = await helper.sendApiRequest('GET', 'setting');
      const response = await res.json();

      const config = response.configs.reduce((acc, conf) => {
        acc[conf.key] = conf.value;
        return acc;
      }, {});

      setLoading(false);
      setFormValues(config);
    } catch (error) {
      console.log('error from getting setting data: ', error);
    }
  };

  if (isLoading) {
    return (
      <>
        <Spinner animation="border" variant="primary" />
      </>
    );
  }

  return (
    <>
      {isLoading ? (
        <>
          <Spinner animation="border" variant="primary" />
        </>
      ) : (
        <>
          <Tabs defaultActiveKey="api" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="api" title="Active Api" className="mb-4">
              <ApiIntegrationForm defaultValues={formValues} />
            </Tab>
            <Tab eventKey="apikeys" title="Api Keys" className="mb-4">
              <ApiKeysForm defaultValues={formValues} />
            </Tab>
            <Tab eventKey="profile" title="Customer & Notiifcation" className="mb-4">
              <CustomerSettingsForm defaultValues={formValues} />
            </Tab>
          </Tabs>
        </>
      )}
    </>
  );
};

export default SettingPage;
