import { useFormInputValidation } from "react-form-input-validation";
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import helper from "../../utils/helper";
import { useState } from "react";

const ForgotPasswordComponent = ({ setFormState, showSuccessMessage, setEmail }) => {
    const [serverErrors, setServerErrors] = useState({})
    const [fields, errors, form] = useFormInputValidation({
        email: '',
    }, {
        email: "required|email",
    });

    const onSubmit = async (event) => {
        const isValid = await form.validate(event);
        if (isValid) {
            const respone = await helper.sendApiRequest('POST', 'auth/forgot-password', fields)
            const result = await respone.json()
            if (result.success) {
                // navigate('/')
                setEmail(fields.email)
                setFormState('validateOtp');
                showSuccessMessage(result.message)
            } else {
                if (result.errors) {
                    setServerErrors(result.errors)
                } else {
                    alert('Unable to process, please try later')
                }
            }
        }
    }



    return <>
        <h4 className='midHead'>Forgot Password</h4>
        <Form noValidate onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail" >
                <Form.Label>Email ID</Form.Label>
                <Form.Control
                    name="email"
                    type="email" placeholder="Type Your email"
                    onChange={form.handleChangeEvent}
                    isInvalid={errors.email || serverErrors.email}
                />

                <Form.Control.Feedback type="invalid">
                    {errors.email || ''}
                </Form.Control.Feedback>

                <Form.Control.Feedback type="invalid">
                    {serverErrors.email?.msg || ''}
                </Form.Control.Feedback>

            </Form.Group>
            <div className="tablCont">
                <div className='submitBtn'>
                    <button className="btn btn-primary" >Submit</button>
                </div>
            </div>
        </Form>
    </>
}

export default ForgotPasswordComponent