import config from './config'

const helper = {
    sendApiRequest: (method= 'GET', url = '/', data = {}) => {
        const baseUrl = config.baseApiUrl;
        const token = localStorage.getItem('token')
        var headers = {
            'Authorization': `Bearer ${token}`
        }
        if((['POST', 'PATCH']).includes(method.toUpperCase())){

            if(!(data instanceof FormData)){
                data = JSON.stringify(data)
                headers["Content-Type"] = "application/json"
            }

            return fetch( `${baseUrl}${url}`, {
                method: method,
                headers: headers,
                body: data
            })
        } else {
            const fullUrl = new URL(`${baseUrl}${url}`);
            const queryString =  new URLSearchParams(data).toString();
            headers["Content-Type"] = "application/json"
            return fetch( `${fullUrl}?${queryString}`, {
                method: method,
                headers: headers,
            })
        }

    }
}

export default helper;