import Modal from 'react-bootstrap/Modal';
import helper from './../../utils/helper';
import Loader from '../commons/Loader';
import { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

const CampaignDetail = ({ campaign, closeModal }) => {
  const [isLoading, setLoaing] = useState(true);
  const [campaignReport, setCampaignReport] = useState({
    notificationClickCount: 0,
    notificationCount: 0,
  });
  useEffect(() => {
    getCampaignDetail();
  }, []);
  const getCampaignDetail = async () => {
    try {
      const res = await helper.sendApiRequest(`GET`, `campaign/${campaign._id}`);
      const response = await res.json();
      setCampaignReport(response.data);
      setLoaing(false);
    } catch (error) {
      alert('Unable to fetch details, please try again');
      closeModal();
    }
  };
  return (
    <>
      <Modal
        show={true}
        className="modalPopup"
        // onHide={(handleClose)}
        backdrop="static"
        keyboard={false}
        size="sm"
      >
        <Modal.Dialog className="dilogWrap">
          <Modal.Header closeButton onClick={closeModal}>
            <Modal.Title>Report</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {isLoading ? (
              <Loader />
            ) : (
              <Card>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    Notification Send Count: {campaignReport.notificationCount}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Notification Click Count: {campaignReport.notificationClickCount}
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            )}
          </Modal.Body>
        </Modal.Dialog>
        {/* <Container className='mt-4 mb-2'>
                {
                    isLoading ? <Loader /> :
                    <Card >
                    <Card.Header>Featured</Card.Header>
                    <ListGroup variant="flush">
                        <ListGroup.Item>Cras justo odio</ListGroup.Item>
                        <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
                        <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
                    </ListGroup>
                </Card>

                }
            </Container> */}
      </Modal>
    </>
  );
};

export default CampaignDetail;
