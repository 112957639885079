import React from 'react';
import Form from 'react-bootstrap/Form';
import { useFormInputValidation } from "react-form-input-validation";
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import helper from '../../utils/helper';
import { useNavigate } from 'react-router-dom';
import { setUser, removeUser, selectLoggedInUserToken } from '../../slices/loggedInUserSlice';
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';
import { setToast } from '../../slices/toastMessageSlice';

export default function Login() {
    const user = useSelector(selectLoggedInUserToken)
    const [serverErrors, setServerErrors] = useState({})
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const [fields, errors, form] = useFormInputValidation({
        email: '',
        password: '',
    }, {
        email: "required|email",
        password: 'required'
    });


    const onSubmit = async (event) => {
        const isValid = await form.validate(event);
        if (isValid) {
            const respone = await helper.sendApiRequest('POST', 'auth/login/', fields)
            const result = await respone.json()
            if (result.success) {
                // navigate('/')
                dispatch(setUser({
                    token: result.token,
                    user: result.user
                }))
                dispatch(setToast({
                    type: 'primary',
                    message: 'Logged in successully',
                    title: 'Success'
                }))
            } else {
                if (result.errors) {
                    setServerErrors(result.errors)
                } else {
                    // alert('Unable to login')
                    dispatch(setToast({
                        type: 'primary',
                        message: 'Logged in successully',
                        title: 'Warning'
                    }))
                }
            }
        }
    }

    return (
        <>

            <h4 className='midHead'>Login</h4>
            <Form noValidate onSubmit={onSubmit}>
                <Form.Group
                    className="mb-3"
                    controlId="exampleForm.email"
                >
                    <Form.Label>Email ID</Form.Label>
                    <Form.Control type="email" placeholder="Enter email"
                        name="email"
                        className="mb-3"
                        onChange={form.handleChangeEvent}
                        // value={fields.email}
                        isInvalid={errors.email || serverErrors.email} />


                    <Form.Control.Feedback type="invalid">
                        {errors.email || ''}
                    </Form.Control.Feedback>

                    <Form.Control.Feedback type="invalid">
                        {serverErrors.email?.msg || ''}
                    </Form.Control.Feedback>

                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword"
                >
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password"
                        name="password"
                        onChange={form.handleChangeEvent}

                        isInvalid={errors.password || serverErrors.password} />

                    <Form.Control.Feedback type="invalid">
                        {errors.password || ''}
                    </Form.Control.Feedback>

                    <Form.Control.Feedback type="invalid">
                        {serverErrors.password?.msg || ''}
                    </Form.Control.Feedback>

                </Form.Group>
                <div className="tablCont">

                    <Button type="submit" className="btn btn-primary">Submit</Button>

                </div>
                <div className='subBtn secSpcTop'>
                    <div className="subText"><Link to="/forgot-password" >Forgot password</Link></div>
                </div>
            </Form>

        </>
    )
}