import { useState, useEffect } from 'react';
import Loader from '../../components/commons/Loader';
import Table from 'react-bootstrap/Table';
import helper from '../../utils/helper';
import Form from 'react-bootstrap/Form';
import Paginate from './../../components/commons/Paginate';
import Button from 'react-bootstrap/Button';
import ConfirmationModal from './../../components/commons/ConfirmationModal';
import { Link } from 'react-router-dom';
import EditCampaign from '../../components/campaign/EditCampaign';
import CampaignDetail from '../../components/campaign/CampaignDetail';
import { setToast } from '../../slices/toastMessageSlice';
import { useDispatch } from 'react-redux';
import { FaClipboardList, FaEdit, FaRegTrashAlt } from 'react-icons/fa';
const CampaignList = () => {
  const dispatch = useDispatch();

  const [masterData, setMastarData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoaded, setLoaded] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [aciveItemIndex, setActiveItemIndex] = useState(null);
  const [editCampaign, setEditCampaign] = useState(null);
  const [cammpaignDetail, SetCammpaignDetail] = useState(null);

  useEffect(() => {
    try {
      fetchData();
    } catch (error) {}
  }, [page]);

  const fetchData = async () => {
    const response = await helper.sendApiRequest('GET', 'campaign', {
      page: page,
    });
    const data = await response.json();

    if ('success' in data && data.success) {
      setMastarData(data.campaignData);
      setLoaded(true);
      setTotalPage(data.totalPages);
    } else {
      dispatch(
        setToast({
          type: 'danger',
          message: 'Unable to process your request',
          title: 'Error',
        }),
      );
    }
  };

  const toggleActive = async (index, event) => {
    const item = masterData[index];
    try {
      await helper.sendApiRequest('PATCH', `campaign/${item._id}/update-active-status`, {
        isActive: !item.isActive,
      });
    } catch (error) {
      dispatch(
        setToast({
          type: 'danger',
          message: 'Unable to process',
          title: 'Error',
        }),
      );
    }
  };

  const deleteItem = async () => {
    const item = masterData[aciveItemIndex];
    try {
      const res = await helper.sendApiRequest('DELETE', `campaign/${item._id}`, {
        isActive: !item.isActive,
      });

      const response = await res.json();
      if (response.success) {
        masterData.splice(aciveItemIndex, 1);
        setActiveItemIndex(null);
        setShowDeleteModal(false);
        setPage(1);
        dispatch(
          setToast({
            type: 'success',
            message: response.message ? response.message : 'Campaign deleted',
            title: 'Success',
          }),
        );
      } else {
        setActiveItemIndex(null);
        setShowDeleteModal(false);
        dispatch(
          setToast({
            type: 'danger',
            message: response.message ? response.message : 'Unable to process',
            title: 'Error',
          }),
        );
      }
    } catch (error) {}
  };

  const openDeleteModal = (index) => {
    setActiveItemIndex(index);
    setShowDeleteModal(true);
  };

  const openDetailModal = (index) => {
    SetCammpaignDetail(masterData[index]);
  };

  const openEditModal = (index) => {
    setEditCampaign(masterData[index]);
  };

  const closeModal = () => {
    setEditCampaign(null);
    SetCammpaignDetail(null);
  };

  return (
    <>
      {!isLoaded ? (
        <Loader />
      ) : (
        <>
          <div className="topHead">
            <h3>Campaign</h3>
            <Link to="/campaign/create">
              <Button variant="primary">Add New</Button>
            </Link>
          </div>
          <Table striped bordered hover size="sm" responsive>
            <thead>
              <tr>
                <th>Source </th>
                <th>Destination</th>
                <th>Notification Title</th>
                <th>Notification Body</th>
                <th>Icon</th>
                <th>Status</th>
                <th style={{ textAlign: 'center' }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {masterData.length > 0 ? (
                masterData.map((item, index) => {
                  return (
                    <tr key={item._id}>
                      <td>{item.sourceDomain}</td>
                      <td>{item.destinationDomain}</td>
                      <td>{item.notificationTitle}</td>
                      <td>{item.notificationBody}</td>
                      <td>
                        <img src={item.iconImageUrl} width={50} />
                      </td>
                      <td>
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="custom-switch"
                          label=""
                          defaultChecked={item.isActive}
                          onChange={(e) => toggleActive(index, e)}
                        />
                      </td>

                      <td style={{ textAlign: 'center' }}>
                        <ul className="actionBtn">
                          <li>
                            <a
                              href="#"
                              onClick={() => {
                                openEditModal(index);
                              }}
                            >
                              {' '}
                              <FaEdit />
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              onClick={() => {
                                openDeleteModal(index);
                              }}
                            >
                              <FaRegTrashAlt />
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              onClick={() => {
                                openDetailModal(index);
                              }}
                            >
                              <FaClipboardList />
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={7}>No items found</td>
                </tr>
              )}
            </tbody>
          </Table>

          <Paginate totalPage={totalPage} pageChangeHandler={setPage} currentPage={page} />
        </>
      )}

      {showDeleteModal ? (
        <ConfirmationModal
          message="This will delete the item"
          modalHandler={setShowDeleteModal}
          confirmHandler={deleteItem}
        />
      ) : null}

      {editCampaign ? <EditCampaign campaign={editCampaign} closeEditModal={closeModal} /> : null}
      {cammpaignDetail ? (
        <CampaignDetail campaign={cammpaignDetail} closeModal={closeModal} />
      ) : null}
    </>
  );
};

export default CampaignList;
